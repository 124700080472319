<script setup lang="ts">
import { toRef } from 'vue'
// Properties and events.
//
const emit = defineEmits(['process:toggle'])
const props = defineProps<{
  active: boolean
  styleOpts?: string
  primaryText?: string
  secondaryText?: string
  singleToggle?: boolean
  // Hex colour overrides.
  //
  primaryColour?: string
  secondaryColour?: string
}>()
// Main variables.
//
const active = toRef(props, 'active')
const styleOpts = toRef(props, 'styleOpts')
const singleToggle = toRef(props, 'singleToggle')
const primaryText = toRef(props, 'primaryText')
const secondaryText = toRef(props, 'secondaryText')
const primaryColour = toRef(props, 'primaryColour')
const secondaryColour = toRef(props, 'secondaryColour')
const primaryStyles = {
  background: primaryColour.value || 'inherit',
}
const secondaryStyles = {
  background: secondaryColour.value || 'inherit',
}
// Functions.
//
const processToggle = function processToggle(target: string) {
  if (target === 'toggle' && !active.value) {
    emit('process:toggle', 'primary')
  } else if (target === 'toggle' && active.value) {
    emit('process:toggle', 'secondary')
  }
}
</script>

<template>
  <div v-if="singleToggle">
    <div class="rk-switch" :class="[styleOpts]">
      <div
        class="rk-switch__toggle"
        :class="{ active: active }"
        @click="processToggle('toggle')"
      >
        <i class="rk-icon fontello icon-solid-circle"></i>
      </div>
      <input class="rk-switch__input" />
    </div>
  </div>
  <div v-else class="rk-switch" :class="[styleOpts]">
    <div
      class="rk-switch__primary"
      :class="{ active: active, 'p-1': !primaryText }"
      :style="[primaryColour && active ? primaryStyles : '']"
      @click="processToggle('toggle')"
    >
      <span v-if="primaryText" class="rk-switch__text">
        {{ primaryText }}
      </span>
      <i v-else class="rk-icon fontello icon-confirmed"></i>
    </div>
    <div
      class="rk-switch__secondary"
      :class="{ active: !active, 'p-1': !secondaryText }"
      :style="[secondaryColour && !active ? secondaryStyles : '']"
      @click="processToggle('toggle')"
    >
      <span v-if="secondaryText" class="rk-switch__text">
        {{ secondaryText }}
      </span>
      <i v-else class="rk-icon fontello icon-cancelled"></i>
    </div>
    <input class="rk-switch__input" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/template/components/switch';
</style>
