<template>
  <header
    class="rk-header"
    :class="{
      'active-mobile-menu': menuStatus,
    }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col d-none d-lg-flex align-items-center">
          <div class="rk-header__title">
            RezKit
            <span v-if="authUserData" class="rk-text rk-text--text-sm">
              for {{ authUserData.organization.name }}
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6 ps-0 ps-0">
          <div
            class="d-flex justify-content-end align-items-center position-relative"
          >
            <ToggleSwitch
              :single-toggle="true"
              :active="darkMode"
              style-opts="rk-switch--dark-mode me-3"
              @process:toggle="toggleDarkMode($event === 'primary' || false)"
            />
            <div class="dropdown me-3">
              <Btn
                style-opts="rk-btn--icon p-1"
                @process:click="toggleRecentlyViewed"
              >
                <i class="rk-icon rk-icon--text-sm fontello icon-clock"></i>
              </Btn>
              <RecentlyViewed :show="showRecentlyViewed" />
            </div>
            <div class="dropdown">
              <Btn
                style-opts="rk-btn--dropdown icon-btn-mobile"
                @process:click="toggleMenu"
              >
                <span
                  v-if="authUserData"
                  class="rk-header__username d-none d-lg-inline-block me-2"
                >
                  {{ authUserData.name }}
                </span>
                <i
                  class="rk-icon rk-icon--text-xs fontello icon-chevron-down d-none d-lg-inline-block"
                ></i>
                <i
                  class="rk-icon rk-icon--text-sm fontello icon-cog d-inline-block d-lg-none"
                ></i>
              </Btn>
              <ul
                class="rk-menu rk-menu--dropdown dropdown-menu"
                aria-labelledby="userMenu"
                :class="{ show: btnActive }"
              >
                <li class="mb-2">
                  <Btn
                    base-style="light"
                    style-opts="rk-btn--full-size"
                    @process:click="navigateTo('/organizations')"
                  >
                    Organizations
                  </Btn>
                </li>
                <li class="mb-2">
                  <Btn
                    base-style="light"
                    style-opts="rk-btn--full-size"
                    @process:click="navigateTo('/organizations/users')"
                  >
                    Users
                  </Btn>
                </li>
                <li>
                  <Btn
                    style-opts="mt-2 rk-btn--full-size"
                    @process:click="userLogout"
                  >
                    <i
                      class="fontello rk-icon rk-icon--text-xs icon-sys-login me-1"
                    ></i>
                    Log Out
                  </Btn>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
// GQL queries.
//
import getMeQuery from '@/apollo/queries/me.graphql'

export default defineComponent({
  computed: {
    authUserData() {
      return useAuthStore().user
    },
    menuStatus() {
      return useGlobalStore().menu
    },
    btnActive() {
      return useGlobalStore().menuPopover
    },
    stateOverlays() {
      return useGlobalStore().overlays
    },
    showRecentlyViewed() {
      return useGlobalStore().recentlyViewed
    },
    darkMode() {
      return useGlobalStore().darkMode
    },
  },
  data() {},
  methods: {
    async getUser() {
      const resp = await this.$apollo
        .query({
          query: getMeQuery,
        })
        .catch((e: any) => {
          console.log(e)
        })
      if (resp) {
        useAuthStore().user = resp.data.users.user
        useAuthStore().orgId = resp.data.users.user.organization.id
        this.getAllTags(resp.data.users.user.organization.id)
      }
    },
    async userLogout() {
      useAuthStore().user = null
      useAuthStore().token = null
      useAuthStore().session = null
      useGlobalStore().menuPopover = false
      useGlobalStore().removeOverlay({ level: 1 })
      await useApollo().onLogout()
      localStorage.clear()
      sessionStorage.clear()
      document.cookie =
        'rezkit_token=;domain=' + window.location.hostname + ';path=/'
      await navigateTo('/login')
    },
    toggleMenu() {
      useGlobalStore().toggleMenuPopover()
      if (this.btnActive) {
        useGlobalStore().addOverlay({ level: 1 })
      } else {
        useGlobalStore().removeOverlay({ level: 1 })
      }
    },
    toggleRecentlyViewed() {
      if (this.showRecentlyViewed) {
        useGlobalStore().toggleRecentlyViewed()
        useGlobalStore().removeOverlay({ level: 1 })
      } else {
        useGlobalStore().toggleRecentlyViewed()
        useGlobalStore().addOverlay({ level: 1 })
      }
    },
    toggleDarkMode(state: boolean) {
      useGlobalStore().toggleDarkMode(state)
    },
    getAllTags(organizationId: string) {
      useTagStore().getTags(organizationId, useApollo().clients!.default)
    },
  },
  mounted() {
    if (useAuthStore().token && !useAuthStore().user) {
      this.getUser()
    }
  },
  watch: {
    stateOverlays: {
      deep: true,
      handler() {
        if (
          useGlobalStore().overlays &&
          useGlobalStore().overlays.length === 0
        ) {
          useGlobalStore().menuPopover = false
        }
      },
    },
  },
})
</script>

<style lang="scss"></style>
