<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  show: boolean
}>()
// Main variables.
//
const show = toRef(props, 'show')
const recentlyViewed = storeToRefs(useUsabilityStore()).recentlyViewed
</script>

<template>
  <div
    class="rk-menu rk-menu--dropdown rk-menu--recent-items dropdown-menu me-n4 px-0"
    aria-labelledby="recentlyViewed"
    :class="{ show: show }"
  >
    <div class="px-3">
      <div class="text-start">
        <h6 class="mb-0">Recently Viewed</h6>
      </div>
      <hr class="my-2" />
    </div>
    <ul
      v-if="recentlyViewed && recentlyViewed?.length > 0"
      class="rk-list rk-list--recently-viewed text-start"
    >
      <li
        v-for="(item, index) in recentlyViewed.slice(0, 10)"
        v-show="index !== 5"
        :key="index"
      >
        <div class="d-flex align-items-center justify-content-between mb-3">
          <Btn
            :link="true"
            :path="item.path"
            base-style="light"
            style-opts="rk-btn--recent"
          >
            <span v-if="item.name" class="rk-capitalize">
              {{ item.name }}
            </span>
            <span v-else>View</span>
          </Btn>
          <NuxtLink
            :to="item.path"
            class="rk-link rk-link--default rk-link--text-sm rk-underline text-end ms-3"
          >
            <span v-if="item.description">
              {{ item.description }}
            </span>
            <span v-else>
              <em class="rk-emphasized rk-capitalize">
                {{ item.name }}
              </em>
            </span>
          </NuxtLink>
        </div>
      </li>
    </ul>
    <InlineAlert
      v-else
      base-style="secondary"
      class="px-3"
      visible
      text="No recently viewed items"
    />
  </div>
</template>

<style lang="scss"></style>
